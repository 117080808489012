import { computed, Injectable, signal} from '@angular/core';
import { IUser } from '@app/interface/index';
import { map, Observable } from 'rxjs';
import { environment } from '@environments/environment'
import { ApiV5Service } from '@app/service/api';
import { StorageService } from '@app/service/utils';
import { FormGroup } from '@angular/forms';

const API_AC: string = environment.API_AC;

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    //signals 
    #backgroundUrlStorage = signal<string>('');
    #stepRegisterForm = signal<number>(1);
    #titleForm = signal<string>('registro.form_datos_personal');
    public stepCurrent = computed(() => this.#stepRegisterForm());
    public backgroundUrl = computed(()=> this.#backgroundUrlStorage());
    public titleForm = computed(()=> this.#titleForm());

    constructor(
        private apiV5Service: ApiV5Service,
        private storageService: StorageService
    ){
        this.getUrlBackground();
    }

    public updateStep(nextStep:boolean) {
        if(nextStep){
            this.#stepRegisterForm.update(v => v + 1)
        }else{
            this.#stepRegisterForm.update(v => v - 1)
        }
        if(this.#stepRegisterForm() === 1){
            this.#titleForm.set('registro.form_datos_personal');
        }else if(this.#stepRegisterForm() === 2){
            this.#titleForm.set('registro.form_datos_contacto');
        }else if(this.#stepRegisterForm() === 3){
            this.#titleForm.set('registro.form_datos_contrasena');
        }
    }

    public setNewUserObjetc(formNewUser: FormGroup): Promise<IUser> {
        return new Promise((resolve, reject) => {
            if(formNewUser){
                const formValues = formNewUser.value;
                const formPasswordGroup = formNewUser.controls['passwordGroup'] as FormGroup;
                let userDocument = formValues.personal.documentNumber;
                userDocument = userDocument.replace(/-/g, '');
                const user: IUser = {
                    name: formValues.personal.name,
                    username: userDocument.toUpperCase().replace(/ /g, ""),
                    surname: formValues.personal.surname,
                    title: formValues.personal.title.value,
                    documentType: formValues.personal.documentType.value,
                    documentNumber: userDocument.toUpperCase().replace(/ /g, ""),
                    birthDate: `${formValues.personal.year.value}-${formValues.personal.month.value}-${formValues.personal.day.value}`,
                    privacyPolicyChecked: formValues.accountagreed ? 1:0,
                    addressInfoList: [
                        {
                            commercialCountry: {
                                idDyn: formValues.address.country.value.idDyn,
                                isoCode3: formValues.address.country.value.isoCode3,
                                name: formValues.address.country.value.name,
                            },
                            commercialCity: formValues.address.locality,
                            commercialAddress: formValues.address.address,
                            commercialPostalCode: formValues.address.postalCode,
                            commercialProvince: {
                                idDyn: formValues.address.province.value.idDyn,
                                name: formValues.address.province.value.name
                            }
    
                        }
                    ],
                    contactInfoList: [
                        {
                            phoneNumber: formValues.address.phone,
                            email: formValues.address.email
                        }
                    ],
                    password:formPasswordGroup.controls['password'].value,
                }
                resolve(user)
            }
            reject("object no valid");
        })
    }

    public registerNewUser(user: IUser): Observable<any>{
        const url = `${API_AC}/api/users/v5/user`;
        return this.apiV5Service.post(url, user).pipe(
            map((response: any) => response)
        )
    }

    private getUrlBackground(): void{
        const urlBg = this.storageService.get('background')
        this.#backgroundUrlStorage.set(urlBg);
    }
}